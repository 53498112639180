<template>
  <div class="row p-10">
    <!-- 휴일구분 -->
    <base-year-combo-box :ref="'baseYearComboBox'"
      @search="onSearch"
    />
  </div> 
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import backEndApi from "../../../api/backEndApi";
import baseYearComboBox from "../../component/comboBox/custom-point/BaseYearComboBox.vue";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  props: [],
  components: {
    baseYearComboBox,
  },
  watch: {

  },
  data() {
    return {
      defaultBaseYear: null
    };
  },
  created() {
  },
  async mounted() {
    this.defaultBaseYear = await this.$refs.baseYearComboBox.setInitCode();
    await this.onSearch();
  },
  methods: {
    async onSearch() {
      let baseYear = this.$refs.baseYearComboBox.getValue() == null ? this.defaultBaseYear : this.$refs.baseYearComboBox.getValue();

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.custom.getCustPointList(baseYear);
        const data = result.data;

        console.log(data);
        this.$emit("search:data", data);
      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    onSave() {
      this.$emit("save");
    },
    onRemove() {
      this.$emit("delete");
    },
    getBaseYear() {
      return this.$refs.baseYearComboBox.getValue();
    }
  }
};
</script>

<style scoped>

</style>
