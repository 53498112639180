<template>
	<!-- 기준연도 -->
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5 p-r-5">
			<span>{{$t('기준 연도')}}</span>
		</div>
		<div class="p-l-5 p-r-5">
			<select class="form-control" ref="baseYear" v-model="baseYear" @change="onChangeBaseYear">
				<option v-for="(code, index) in baseYearList" :key="index" :value="code">
					{{ code }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import AppErrorHandler from "@src/appErrorHandler";

	export default {
		components: {},
		props: [],
		watch: {},
		data() {
			return {
				baseYear: "",
				baseYearList: null,
			};
		},
		computed: {},
		created() {},
		async mounted() {
			await this.getBaseYearList();
		},
		updated() {},
		destroyed() {},
		methods: {
			async getBaseYearList() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
      try {
        const result = await backEndApi.custom.getBaseYearList();
        this.baseYearList = result.data
          this.baseYear = result.data[result.data.indexOf(new Date().getFullYear())];
      } catch (err) {
        new AppErrorHandler(err)
        .printErrorLog()
        .errHandling();
      }      
    },
    async setInitCode(){
      
			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
      const result = await backEndApi.custom.getBaseYearList();
      return this.baseYear = result.data[result.data.indexOf(new Date().getFullYear())];
    },
    getValue(){
      return this.baseYear;
    },
    focus(){
      this.$refs.baseYear.focus();
    },
    onChangeBaseYear(){
      this.$emit("search")
    }
  },
};
</script>

<style scoped></style>
