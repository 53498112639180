<template>
	<div>
		<vvo v-slot="v">

			<div class="row">
				<b-table
					head-variant="dark"
					show-empty
					hover
					responsive
					ref="holidayTable"
					:style="'min-width:100%; word-break:keep-all'"
					:class="'text-center mb-0'"
					:items="items"
					:fields="fields"
					sticky-header="970px"
					:empty-text="$t('조회된 결과가 없습니다.')"
					table-variant="light"
				>
					<!-- @row-clicked="onRowClicked" -->
					<template #empty="">
						<h4>{{ $t("조회자료 없음") }}</h4>
					</template>

					<template #cell(baseYear)="row">
						<input type="text" class="form-control text-center" v-model="row.item.baseYear" disabled="disabled" :style="{ maxWidth: '100px' }" />
					</template>

					<!-- <template></template> -->

					<template #cell()="row">
						<!-- 관제점 명 -->
						<input v-if="row.field.key == 'baseYear'" type="text" class="form-control text-center" v-model="row.item.baseYear" disabled="disabled" :style="{ maxWidth: '100px' }" />
						<!-- 관제점 그룹 -->
						<label v-else-if="row.field.key == 'ptName'">{{ row.item.ptName }}</label>
						<!-- 기준 연도 -->
						<label v-else-if="row.field.key == 'ptGroupName'">{{ row.item.ptGroupName }}</label>
						<!-- 각 월 -->

						<valid-input
							v-else-if="row.field.key.includes('monthValue')"
							:vid="`${row.item.ptName}_${row.field.label}`"
							:inputType="'number'"
							:inputValue.sync="row.item[row.field.key]"
							:classList="'text-right'"
							:rules="ptAddrRules(row.item.ptName)"
              :isMessage="true"
              :ref="`${row.index}`"
						>
						</valid-input>

						<!-- 버튼 -->
						<div v-else-if="row.field.key === 'btnSection'" class="d-flex flex-row">
							<b-button @click="onValidate(v, onSave, row.item)" class="m-r-5" :style="{ width: '60px' }">{{$t('저장')}}</b-button>
							<b-button @click="onRemove(row.item)" :style="{ width: '60px' }">{{$t('삭제')}}</b-button>
						</div>
					</template>

					<!-- <template #cell(monthValue01)="row">
						<valid-input :vid="'1월'" :inputType="'number'" :inputValue.sync="row.item.monthValue01" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue02)="row">
						<valid-input :vid="'2월'" :inputType="'number'" :inputValue.sync="row.item.monthValue02" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue03)="row">
						<valid-input :vid="'3월'" :inputType="'number'" :inputValue.sync="row.item.monthValue03" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue04)="row">
						<valid-input :vid="'4월'" :inputType="'number'" :inputValue.sync="row.item.monthValue04" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue05)="row">
						<valid-input :vid="'5월'" :inputType="'number'" :inputValue.sync="row.item.monthValue05" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue06)="row">
						<valid-input :vid="'6월'" :inputType="'number'" :inputValue.sync="row.item.monthValue06" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue07)="row">
						<valid-input :vid="'7월'" :inputType="'number'" :inputValue.sync="row.item.monthValue07" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue08)="row">
						<valid-input :vid="'8월'" :inputType="'number'" :inputValue.sync="row.item.monthValue08" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue09)="row">
						<valid-input :vid="'9월'" :inputType="'number'" :inputValue.sync="row.item.monthValue09" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue10)="row">
						<valid-input :vid="'10월'" :inputType="'number'" :inputValue.sync="row.item.monthValue10" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue11)="row">
						<valid-input :vid="'11월'" :inputType="'number'" :inputValue.sync="row.item.monthValue11" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(monthValue12)="row">
						<valid-input :vid="'12월'" :inputType="'number'" :inputValue.sync="row.item.monthValue12" :classList="'text-right'" :rules="rules.CUSTOM_POINT_MONTH_VALUE_RULE"> </valid-input>
					</template>

					<template #cell(btnSection)="row">
						<div class="d-flex flex-row">
							<b-button @click="onSave(row.item)" class="m-r-5" :style="{ width: '60px' }">저장</b-button>
							<b-button @click="onRemove(row.item)" :style="{ width: '60px' }">삭제</b-button>
						</div>
					</template> -->

					<template #cell(selected)="{ rowSelected }">
						<template v-if="rowSelected">
							<span aria-hidden="true">&check;</span>
							<span class="sr-only">Selected</span>
						</template>
						<template v-else>
							<span aria-hidden="true">&nbsp;</span>
							<span class="sr-only">Not selected</span>
						</template>
					</template>
				</b-table>
			</div>
		</vvo>
	</div>
</template>

<style>
	.td-custom-tag-code {
		background-color: #343a40;
		color: white;
		width: 100px;
	}

	.customPointTableTD {
		vertical-align: middle !important;
	}

	.modal-open .ui-datepicker {
		z-index: 2000 !important;
	}
</style>

<script>
	import backEndApi from "../../../../api/backEndApi";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as validMessages from "@src/consts/validMessageConsts";

	export default {
		components: {},
		props: [],
		data() {
			return {
				fields: null,
				items: [],
				emptyText: global.xe.$t("조회자료 없음"),
				selectedItems: [],
				selectedIndex: [],
				holiTypeCodeList: null,
				initData: null,
				rules,
				popupMessages,
				validMessages,
			};
		},
		computed: {
		
		},
		watch: {},
		created() {
			this.setFields();
		},
		mounted() {},
		updated() {},
		methods: {
      ptAddrRules(value) {
				let rules = this.rules.CUSTOM_POINT_MONTH_VALUE_RULE;
				if (value === "평균 재실인원" || value === "건물 연면적") {
					rules = this.rules.CUSTOM_POINT_EXCEPTION_VALUE_RULE;
				}
				return rules;
			},
			async onSave(item) {
				console.log(item);

				let param = [
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "01", customValue: item.monthValue01 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "02", customValue: item.monthValue02 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "03", customValue: item.monthValue03 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "04", customValue: item.monthValue04 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "05", customValue: item.monthValue05 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "06", customValue: item.monthValue06 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "07", customValue: item.monthValue07 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "08", customValue: item.monthValue08 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "09", customValue: item.monthValue09 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "10", customValue: item.monthValue10 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "11", customValue: item.monthValue11 },
					{ ptAddr: item.ptAddr, baseYear: item.baseYear, baseMonth: "12", customValue: item.monthValue12 },
				];

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*
				↓↓↓↓↓
			*/
				try {
					await backEndApi.custom.insertCustomPoint(param);
					this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
					this.$emit("reSearch");
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger)
						.printErrorLog()
						.errHandling();
				}
			},
			async onRemove(item) {
				console.log(item);

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*
				↓↓↓↓↓
			*/

				const result = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
				if (!result.value) return;

				let param = { baseYear: item.baseYear, ptAddr: item.ptAddr };

				try {
					await backEndApi.custom.deleteCustomPoint(param);

					await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
					this.$emit("reSearch");
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE, this.alertDanger)
						.printErrorLog()
						.errHandling();
				}
			},
			setFields() {
				this.fields = [
					{ key: "ptName", label: global.xe.$t("관제점명"), tdClass: "customPointTableTD" },
					{ key: "ptGroupName", label: global.xe.$t("관제점 그룹"), tdClass: "customPointTableTD" },
					{ key: "baseYear", label: global.xe.$t("기준 연도") },
					{ key: "monthValue01", label: global.xe.$t("1월") },
					{ key: "monthValue02", label: global.xe.$t("2월") },
					{ key: "monthValue03", label: global.xe.$t("3월") },
					{ key: "monthValue04", label: global.xe.$t("4월") },
					{ key: "monthValue05", label: global.xe.$t("5월") },
					{ key: "monthValue06", label: global.xe.$t("6월") },
					{ key: "monthValue07", label: global.xe.$t("7월") },
					{ key: "monthValue08", label: global.xe.$t("8월") },
					{ key: "monthValue09", label: global.xe.$t("9월") },
					{ key: "monthValue10", label: global.xe.$t("10월") },
					{ key: "monthValue11", label: global.xe.$t("11월") },
					{ key: "monthValue12", label: global.xe.$t("12월") },
					{ key: "btnSection", label: "" },
				];
			},
			setItems(data) {
				console.log(data);
				this.items = data;
			},
			onPush() {
				this.selectedIndex = [];
				// 신규 행 추가
				this.items.push({
					baseDate: "",
					holiTypeCode: "",
					holiName: "",
					holiDesc: "",
				});
			},
			onPop() {
				if (!this.items[this.items.length - 1]["holiIdx"]) {
					this.items.pop();
				} else {
					this.alertNoti(popupMessages.POINT_CUSTOM_NEW_ITEM_DELETE_POPUP_MESSAGE);
				}

				// =======================================================
				// 선택된 행 제거
				// 신규 행만 제거
				// 기존 행 제거 시 경고창 표출 후 제거 x
				// console.log(`선택된 행 제거`);
				// let flag = false;
				// this.selectedItems.forEach(row => {
				//   if(row["holiIdx"]){
				//     flag = true;
				//     // unselectRow
				//   }
				//   if(flag){
				//     return;
				//   }
				// });

				// if(flag){
				//   this.alertNoti("신규 추가된 행만 제거할 수 있습니다.");

				//   return;
				// }else{
				//   console.log("신규 행만");
				//   this.selectedIndex.forEach(index => {
				//     // this.items.splice(index,1);
				//     delete this.items[index];
				//   });

				//   this.items.forEach((item,index) => {
				//     // this.items.splice(index,1);
				//     console.log("###################item###################");
				//     console.log(item);
				//     console.log("##########################################");
				//     if(!item){
				//       this.items.slice(index,index+1);
				//     }
				//   });

				// this.selectedIndex = [];
				// }
			},
			onDatePicker() {
				console.log("onDatePicker");
			},
		},
	};
</script>
