<template>
	<div class="col-md-12 default">
		<div class="col-xl-12 xeEnergyCostAnalysis">
			<!-- <header-box :title="'사용자정의 관제점 관리'"/> -->
			<header-box :title="`${$t('관제점 구성 관리')} > ${$t('사용자 정의 관제점 설정')}`" />

			<div class="searchArea row">
				<div class="col-md-12 innerBox">
					<search-box :ref="'searchBox'" @search:data="onSearch" />
				</div>
			</div>
			<grid-box :ref="'gridBox'" @reSearch="reSearch" />
		</div>
	</div>
</template>

<script>
	import "element-ui/lib/theme-chalk/index.css";
	// import headerBox from "../../component/headerBox/management/Header.vue";
	import searchBox from "./CustomPointInfoSearch.vue";
	import gridBox from "./CustomPointInfoGrid.vue";
	import headerBox from "../../component/headerBox/analysis/Header.vue";

	export default {
		props: ["type"],
		components: {
			headerBox,
			searchBox,
			gridBox,
		},
		watch: {},
		data() {
			return {};
		},
		created() {},
		mounted() {},
		methods: {
			onSearch(data) {
				console.log(data);
				data.forEach((custom) => {
					custom.baseYear = this.$refs.searchBox.getBaseYear();
				});
				this.$refs.gridBox.submit(data);
			},
			reSearch() {
				this.$refs.searchBox.onSearch();
			},
		},
	};
</script>

<style scoped></style>
