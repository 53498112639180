<template>
  <div class="col-md-12 default">
     <!-- 사용자정의 관제점관리 -->
    <custom-point-info />
  </div>
</template>

<script>
import customPointInfo from "./CustomPointInfo.vue";

export default {
  name: "PointManagement",
  components: {
    customPointInfo
  },
  props: [],
  data() {
    return {
      
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },

  methods: {
   
  },
};
</script>
